<template>
  <div class="pres_total_compra" v-if="pdat">
    <div class="cab">RESUMEN</div>
    <v-sheet v-bind="$cfg.styles.marco">
      <div class="columna" >
        <vllabel
          label="FUNERARIA"
          :txt="pdat.pnfsuma.value | num(2)"
          stilot="text-align:right">
        </vllabel>

        <vllabel
          label="OTROS"
          :txt="pdat.sup_pd.value | num(2)"
          stilot="text-align:right">
        </vllabel>

        <vllabel
          label="TOTAL IMP"
          :txt="pdat.imp.value | num(2)"
          stilot="text-align:right"
        ></vllabel>
        <vllabel
          label="AÑO PAS"
          :txt="pdat.imp_ant.value | num(2)"
          stilot="text-align:right"
        >
        </vllabel>

        <vllabel
          label="DIFER %"
          :txt="diferencia | num('%')"
          stilot="text-align:right"
        ></vllabel>
  
         <vllabel
            label="TOTAL CIA"
            :txt="pdat.imp_cia.value| num(2)"
            stilot="text-align:right"
          ></vllabel>
        
      </div>
    </v-sheet>
  </div>
</template>
<script>
export default {
  props: {
    pdat: { type: Object, default: null },
    edicion: { type: Boolean, default: false }
  },

  computed:{
    diferencia() {
      let dif=Number(this.pdat.imp_ant.value) - Number(this.pdat.imp.value);
      if (isNaN(dif)) return "NO PRES"

      return (Number(this.pdat.imp.value)-Number(this.pdat.imp_ant.value))*100/this.pdat.imp_ant.value;
    }
  }


};
</script>
